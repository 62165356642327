<template>
  <div class="list-info" id="watermark">
    <top-bar :title="'返程人员'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="输入返乡人员名字或身份证号搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="timeShow = !timeShow" :style="{color:timeShow?'#387FF5':'#666666'}">
            {{timeName}}
            <img :src="require(`@/assets/img/${timeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="cityShow=!cityShow" :style="{color:cityShow?'#387FF5':'#666666'}">
            {{cityName}}
            <img :src="require(`@/assets/img/${cityShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="testShow=!testShow" :style="{color:testShow?'#387FF5':'#666666'}">
            {{testName}}
            <img :src="require(`@/assets/img/${testShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <!-- 返程时间 -->
    <van-popup v-model="timeShow" position="bottom">
      <van-datetime-picker v-model="dataForm.time" type="date" title="请选择" :formatter="formatDate" cancel-button-text="重置"
                           @cancel="timeCancel" @confirm="timeConfirm" :maxDate="maxDate"/>
    </van-popup>

    <!-- 所在地区 -->
    <van-popup v-model="cityShow" position="bottom">
      <van-cascader title="请选择" :field-names="fieldNames" :options="getAreaData" :value="cityName" @finish="cityConfirm" @close="cityShow = false"/>
    </van-popup>

    <van-popup v-model="testShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="testList" value-key="label" @finish="testConfirm" @close="testCancel" />
    </van-popup>

    <van-popup v-model="liveWhichShow" position="bottom">
      <van-cascader
          title="请选择"
          :value="dataForm.detailedAddressValue"
          :field-names="fieldNames"
          :options="liveWhichTree"
          active-color="#1989fa"
          @close="liveWhichShow = false"
          @change="liveWhichChange"
          @finish="liveWhichFinish"
      />
    </van-popup>

    <van-dialog v-model="phoneShow" title="" :message="content" @confirm="contentConfirm" show-cancel-button>
    </van-dialog>

    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="item in dataList" :key="item.id">
          <div class="title">
            <div class="title-left">
              返回时间{{' | ' + item.backDate }}
            </div>
            <div class="title-right">
              <van-icon name="phone" color="#3D7EFE" class="content-icon" size="0.6rem" @click="callPhone(item.mobile)"/>
            </div>
          </div>
          <div class="content" @click="getInfo(item.id)">
            <img v-if="item.url" :src="imgUrl(item.url)" class="content-img" @error="error(index, item.sex)"/>
            <img v-else-if="item.sex == '1'" :src="imgMan" class="content-img" />
            <img v-else-if="item.sex == '2'" :src="imgWoman" class="content-img" />
            <div class="content-text">
              <div class="top-text">{{item.userName}} <span>{{item.sex == 1 ?'男':'女'}}</span></div>
              <div class="top-text"><span>居住小区：{{item.subareaName}}</span></div>
              <div class="top-text"><span>身份证号：{{item.idNumber}}</span></div>
              <div class="top-text"><span style="color: #3E7EFE;">何处返回：{{item.returnCity}}</span></div>
            </div>
          </div>
          <div class="btnGroup">
            <van-button class="btn" type="info" size="small" round v-if="item.isIsolation == 0" @click="isLoate(item.community, item.fromAbroad, item.userId)">加入隔离</van-button>
            <van-button class="btn" type="info" size="small" round v-else style="background-color: red; border: 0.2667rem solid red">已被隔离</van-button>
          </div>
        </div>
      </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate, formatterDateTime} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
// import { areaList } from '@vant/area-data';
import cityData from '@/utils/data';
export default {
  components :{
    topBar
  },
  data() {
    return {
      timeName: '返程时间',
      cityName: '返回城市',
      testName: '核酸检测',
      dataForm: {
        time: '', //返程时间
        city: '', //所在地区,
        hasCheck: '',
        communityId: '',
        fromAbroad: '',
        isolateUserId: '',
        isolationType: 0,
        houseId: '',
        detailedAddressValue: ''
      },
      loading: false,
      finished: false,
      timeShow: false,
      cityShow: false,
      testShow: false,
      liveWhichShow: false,
      phoneShow: false,
      pullLoading: false,
      page: 0,
      limit: 10,
      searchValue: '',
      areaList: [],
      content: '',
      testList: [{ id: 0, label: '具有7天内有效阴性核酸检测报告' }, { id: 1, label: '不具7天内有效阴性核酸检测报告' }],
      liveWhichTree: [],
      totalCount: 0,
      dataList: [],
      imgMan: getImageStream('files/wx/images/content/headImgMan.png'),
      imgWoman: getImageStream('files/wx/images/content/headImgWoman.png'),
      maxDate: '',
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      }
    };
  },
  computed: {
    getAreaData () {
      return cityData
    }
  },
  methods: {
    ...mapMutations,
    imgUrl (img) {
      if (img) {
        return getImageStream(img)
      }
    },
    getInfo (id) {
      this.$router.push({path: '/back-info', query: {id: id}})
    },
    callPhone (mobile){
      if (!mobile) {
        this.$toast.fail('联系电话不存在')
      } else {
        this.phoneShow = true
        this.content = '是否拨打电话' + '' + '【' + mobile + '】' + '' + '?'
        this.mobile = mobile
      }
    },
    contentConfirm () {
      window.location.href = 'tel:' + this.mobile
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/epidemic/return/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          searchValue: this.searchValue,
          backDateStart: this.dataForm.time,
          backDateEnd: this.dataForm.time,
          hasCheck: this.dataForm.hasCheck,
          returnCity: this.dataForm.city
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getPlace () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTree`),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.liveWhichTree = data.liveWhichTree
        }
      })
    },
    isLoate (community, fromAbroad, userId) {
      this.dataForm.communityId = community
      this.dataForm.fromAbroad = fromAbroad
      this.dataForm.isolateUserId = userId
      this.liveWhichShow = true
    },
    liveWhichFinish(e) {
      let houseId = this.dataForm.houseId
      if (houseId == -1 || !houseId) {
        this.dataForm.detailedAddressValue = ''
        this.$toast.fail('请选择房屋')
      }
      this.liveWhichShow = false
      if (houseId !== -1) {
        let isolationDate = formatterDateTime(new Date());
        this.$http({
          url: this.$http.adornUrl(`/wxapp/disease/res/save`),
          method: 'post',
          data: this.$http.adornData({
            'community': this.dataForm.communityId,
            'userId': this.dataForm.isolateUserId,
            'isolationDate': isolationDate,
            'userType': this.dataForm.fromAbroad === 1 ? 2 : 1,
            'isolationType': this.dataForm.isolationType,
            'houseId': this.dataForm.houseId
          })
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success({
              message: '加入成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }
    },
    liveWhichChange (value) {
      if (value) {
        let options = value.selectedOptions
        let houseId = -1;
        let option = options[options.length - 1];
        if (option.depth == 2 && option.children == null) {
          houseId = option.value
        } else if (option.depth == 3 && option.children == null) {
          houseId = -1
        } else if (option.depth == 4 && option.children == null) {
          houseId = option.value
        } else {
          houseId = -1
        }
        this.dataForm.houseId = houseId
      }
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
    },
    onSearch () {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      this.page = 0
      this.finished = false
      this.dataList = []
      this.searchValue = ''
      this.cityName = '返回城市'
      this.dataForm.city = ''
      this.getDataList()
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    timeConfirm (value) {
      this.page = 0
      this.dataList = []
      this.timeName = formatterDate(value)
      this.dataForm.time = formatterDate(value)
      this.getDataList()
      this.timeShow = false
    },
    timeCancel () {
      this.page = 0
      this.dataList = []
      this.dataForm.time = ''
      this.timeName = '返程时间'
      this.timeShow = false
      this.getDataList()
    },
    cityConfirm (value) {
      this.page = 0
      this.dataList = []
      this.cityName = value.selectedOptions[2].label
      this.dataForm.city = value.selectedOptions.map((n) => n.label).join('/')
      this.getDataList()
      this.cityShow = false
    },
    cityCancel () {
      this.page = 0
      this.dataList = []
      this.dataForm.city = ''
      this.cityName = '返回城市'
      this.cityShow = false
      this.getDataList()
    },
    testConfirm (value) {
      // debugger
      this.page = 0
      this.dataList = []
      if (value.id == 0) {
        this.testName = '具有'
        this.dataForm.hasCheck = 1
      } else {
        this.testName = '不具有'
        this.dataForm.hasCheck = 0
      }
      this.getDataList()
      this.testShow = false
    },
    testCancel () {
      this.page = 0
      this.dataList = []
      this.dataForm.hasCheck = ''
      this.testName = '核酸检测'
      this.testShow = false
      this.getDataList()
    },
    goAdd () {
      this.$router.push('/car-add')
    }
  },
  created () {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    let nowDate = new Date()
    this.maxDate = nowDate
    this.getDataList()
    this.getPlace()
  }
}
</script>
<style scoped lang="scss">
.list-info .cont .list-item {
  padding: 0 24px 30px;
  .content .content-img {
    width: 140px;
    height: 140px;
    margin-top: 30px;
  }
  .content-text {
    margin-left: 60px;
    margin-top: 50px;
    .top-text:nth-child(1) {
      margin-top: 0;
    }
    .top-text {
      margin-top: 5px;
      span {
        color: #999999;
        font-size: 24px;
      }
    }
  }
}
.carImg {
  width: 28px;
  height: 25px;
  position: relative;
  bottom: 3px;
  margin-right: 10px;
}
.btnGroup{
  text-align: right;
  margin-top: 40px;
  margin-right: 50px;
  .btn {
    //width: 170px;
    line-height: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
  }
}
</style>
